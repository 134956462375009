@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;

}

nav {
  box-shadow: 2px 0px 5px lightgray;
  padding: 10px 10px;
  display: flex;
  align-items: center;
}

nav a {
  position: absolute;
  right: 65px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}
nav a:hover {
  color: #007fda;
}

nav #company-name {
  margin-left: 10px;
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0;
  color: #0c59b5;
  text-align: center;
}

nav #company-name span {
  font-size: .86rem;
  font-weight: 400;
  position: relative;
  bottom: 4px;
  font-family: "Rajdhani", sans-serif;
}

nav img {
  height: 50px;
}

.banner-img {
  width: 100%;
}

#quiz-and-choosen-information-container {
  display: flex;
  box-shadow: 0 0 8px lightgray;
}

/* #quiz-and-choosen-information-container main.root{
  display: none !important;
}

#issue-resolve-container main.root {
  display: block !important;
} */

.quiz-container {
  width: 70%;
  padding: 20px 6%;
  text-align: center;
  position: relative;
}

#quiz-container5 .quiz-options-container {
  flex-wrap: nowrap;
}

.customer-service-img {
  width: 43%;
}

#quiz-container5 .quiz-option {
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

#quiz-container5 {
  padding: 20px 3%;
}

#quiz-container5 .quiz-option {
  border: none;
}

#quiz-container5 .quiz-option:hover {
  border: none;
}

#quiz-container5 .quiz-option span {
  margin-left: 15px;
}

#quiz-container5 .checkbox-container {
  box-shadow: none;
}

#quiz-container3 {
  padding: 20px 3%;
}

#quiz-container3 .email-img {
  width: 46%;
  height: 100%;
}

#quiz-container3 .email-input-container {
  width: 54%;
}

#quiz-container3 .form-label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.1rem;
  text-align: start;
}

#quiz-container4 {
  padding: 20px 3%;
}

#quiz-container4 .manufacturer-img {
  width: 46%;
  height: 100%;
}

#quiz-container4 .email-input-container {
  width: 54%;
}

#quiz-container4 .form-label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.1rem;
  text-align: start;
}

#quiz-container6 {
  padding: 20px 3%;
}

#quiz-container6 .phone-img {
  width: 46%;
  height: 100%;
}

#quiz-container6 .email-input-container {
  width: 54%;
}

.customer-service-img {
  width: 46%;
}

#quiz-container6 .form-label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.1rem;
  text-align: start;
}

.quiz-container h5 {
  text-align: center;
  font-size: 1.35rem;
  font-weight: 700;
}

.quiz-options-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.quiz-option {
  position: relative;
  box-shadow: 0 0 3px lightgray;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 15px;
  border: 1px solid transparent;
}

.quiz-option-title {
  background-color: #a9c5ffa3;
  color: black;
  padding: 5px 0px;
  width: 100%;
  margin-bottom: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: .2s all ease-in-out;
}

.quiz-option:hover .quiz-option-title {
  background-color: #2f6ef1;
  color: white;
}

.quiz-option:hover {
  border: 1px solid #2f6ef1;
}

.quiz-option .quiz-option-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
}

.quiz-option.selected .quiz-option-title {
  background-color: #2f6ef1;
  color: white;
}

.quiz-option.selected {
  border: 1px solid #2f6ef1;
}

.quiz-option-content {
  width: 200px;
  height: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.quiz-option-content img {
  height: 50px;
  width: 50px;
  position: relative;
  top: 29px;
}

.next-btn {
  position: absolute;
  bottom: 14%;
  right: 0;
  width: 130px;
}





#choosen-information-container {
  background-color: #2f6ef1;
  color: white;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  padding: 45px 30px 45px 35px;
  min-height: 700px;
  position: relative;
}

#choosen-information-container h6 {
  font-size: .9rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: start;
}

#choosen-information-container h6 span {
  font-weight: 800;
  font-size: 1.2rem;
  margin-right: 10px;
}

#choosen-information-container p {
  text-transform: uppercase;
  font-size: .9rem;
  font-weight: 600;
  top: 10px;
  position: relative;
}

#choosen-information-container p {
  opacity: 0;
  transition: 1s all ease-in-out;
}

#choosen-information-container p.infoVisible {
  opacity: 1;
}

.bg-design {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.15;
}





/* checkbox css */
/* Hide the default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  border-radius: 5px;
}

.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  border-radius: 5px;
  box-shadow: 2px 2px 0px rgb(183, 183, 183);
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 2.3em;
  width: 2.3em;
  background-color: #ccc;
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
  box-shadow: 3px 3px 0px rgb(183, 183, 183);
  transition: all 0.2s;
  opacity: 1;
  background-image: linear-gradient(45deg, #2f6ef1 0%, #5d8ffb 100%);
}

.checkbox-container input~.checkmark {
  transition: all 0.2s;
  opacity: 1;
  box-shadow: 1px 1px 0px rgb(183, 183, 183);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  opacity: 0;
  transition: all 0.2s;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
  opacity: 1;
  transition: all 0.2s;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 15px;
  top: 7px;
  width: 12px;
  height: 25px;
  border: solid white;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}




/* Add this to your App.css or a separate CSS file */

.issue-resolve-container {
  padding: 20px;
}

.accordion-button {
  font-weight: bold;
}

.accordion-body {
  font-size: 1rem;
  padding: 15px;
}




/* Loader css */

.loader {
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  z-index: 1000;
}

.m-100vh {
  height: 100vh;
}

.notification-box {
  border: 2px solid #007fda;
  box-shadow: 3px 3px 15px gray;
  border-radius: 8px;
}

.blue {
  color: #007fda;
}

.top-logo-img {
  background-color: transparent;
  height: 50px;
  width: 50px;
  font-size: 24px;
  border: 2px solid #007fda;
  color: #007fda;
  text-align: center;
  line-height: 50px !important;
  border-radius: 100%;
  display: inline-block;
  cursor: pointer;
}

.middile-text-p {
  padding: 25px;
  background-color: #007fda29;
  border-radius: 6px;
  font-size: 1rem;
}

.spinner-border {
  --bs-spinner-border-width: 4px;
}



/* Issue Resolve CSS */
.issue-resolve-container {
  display: flex;
}

.issue-faq-container {
  width: 50%;
}

.chatbot-outer-container {
  width: 50%;
}

.issue-name {
  font-weight: 700;
  font-size: 1.5rem;
}

.issue-name span {
  color: #007fda;
  text-transform: capitalize;
}

.img-disclaimer {
  margin-bottom: 50px;
  text-align: left;
  font-size: .7rem;
}


@media screen and (max-width: 600px) {
  nav a {
    right: 13px;
    font-size: .8rem;
  }
  #quiz-and-choosen-information-container {
    flex-direction: column;
  }

  .quiz-container {
    width: 100%;
  }

  #choosen-information-container {
    width: 100%;
  }

  .quiz-option {
    margin-bottom: 25px;
  }

  .quiz-options-container {
    flex-direction: column;
  }

  #quiz-container3 .email-input-container {
    width: 100%;
  }

  #quiz-container3 .email-img {
    width: 90%;
  }

  .next-btn {
    position: relative;
    bottom: 0;
    right: 0;
    width: 100%;
    margin-top: 20px;
    font-weight: 600;
  }

  #quiz-container4 .manufacturer-img {
    width: 90%;
  }

  #quiz-container4 .email-input-container {
    width: 100%;
  }

  .customer-service-img {
    width: 90%;
  }

  #quiz-container5 .quiz-option {
    justify-content: start;
  }

  #quiz-container5 .quiz-options-container {
    align-items: start;
  }

  #quiz-container6 .phone-img {
    width: 90%;
  }

  #quiz-container6 .email-input-container {
    width: 100%;
  }

  footer button {
    width: 35%;
    margin-top: 10px;
  }

  .issue-resolve-container {
    flex-direction: column;
  }

  .issue-faq-container {
    width: 100%;
  }

  .chatbot-outer-container {
    width: 100%;
  }

  .device-icons-container {
    flex-direction: column;
  }

  .device-icons-wrapper {
    margin-top: 10px;
  }

  .loader-info-container {
    padding: 1rem 1.2rem !important;
  }

  .loader-info-container img {
    width: 60% !important;
  }
}